<template>
  <div class="flex items-start rounded p-4 md:px-6 md:py-5" :class="theme">
    <InfoIcon class="mr-3 w-4 shrink-0 pt-1 md:w-5" />

    <slot v-if="type !== 'error'">
      <div>
        <p class="mt-0.5 font-medium md:text-base">
          {{ successMessage || $t('form_submit_success') }}
        </p>
        <p v-if="successText" class="mt-1 md:text-base" v-html="successText" />
      </div>
    </slot>

    <slot v-else name="error">
      <p class="mt-0.5 font-medium md:text-base">
        {{ $t('form_submit_error') }}
      </p>
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  type: { type: String, default: 'idle' },
  successMessage: { type: String, default: null },
  successText: { type: String, default: null },
})

const theme = computed(() => {
  if (props.type === 'error') return 'bg-red/10 text-red'

  return 'bg-blue-50 text-blue-800'
})
</script>
